export const calTrueVal = (num) => num >= 10 ? num - 10 : num;

// ob
export const calObComposition = (rnum) => {
    const num = parseInt(rnum);
    const sec = num % 10;
    const first = Math.floor(num / 10);
    const bSec = calTrueVal(sec + 5);
    const bFirst = calTrueVal(first + 5);

    return [
        bFirst * 100 + bSec * 10 + bFirst,
        bFirst * 100 + sec * 10 + bFirst,
        first * 100 + bSec * 10 + first,
        first * 100 + sec * 10 + first
    ]
}

// Nad
export const calNadComposition = (rnad) => {
    const num = parseInt(rnad);
    const first = Math.floor(num / 10);
    const sec = num % 10;

    const realFirst = Math.min(first, sec);
    const realSec = Math.max(first, sec);
    const nadArr = [];
    for (let i = realFirst; i < realSec; i++) {
        for (let j = realFirst; j < realSec; j++) {
            nadArr.push(i * 10 + j);
        }
    }
    return nadArr;
}

// pek
export const calPekComposition = () => [0, 11, 22, 33, 44, 55, 66, 77, 88, 99];
// taspek
export const calMostPekComposition = () => [4, 6, 51, 15, 17, 60, 62, 26, 28, 71, 73, 37, 39, 82, 84, 48, 93, 95];

// Sum
export function findNumbersWithDigitSum(x) {

    const results = [];
    for (let i = 0; i <= 99; i++) {
        const digits = String(i).split('').map(Number);
        const sum = digits.reduce((acc, digit) => acc + digit, 0);
        if (sum === x && digits.length <= 2) {
            results.push(i);
        }
    }
    if (x > 8)
        return results;
    return [...results, ...findNumbersWithDigitSum(10 + x)];
}
// Sum below 10 
export function listNumbersWithSumBelow10() {
    const results = [];
    for (let i = 0; i <= 99; i++) {
        const digits = String(i).split('').map(Number);
        const sum = digits.reduce((acc, digit) => acc + digit, 0);
        if (sum < 10) {
            results.push(i);
        }
    }
    return results;
}
// Sum over 10 
export function listNumbersWithSumAbove10() {
    const results = [];
    for (let i = 10; i <= 99; i++) {
        const digits = String(i).split('').map(Number);
        const sum = digits.reduce((acc, digit) => acc + digit, 0);
        if (sum > 10) {
            results.push(i);
        }
    }
    return results;
}
// bebe
export const listNumberBebe = () => [0, 1, 2, 3, 4, 10, 11, 12, 13, 14, 20, 21, 22, 23, 24, 30, 31, 32, 33, 34, 40, 41, 42, 43, 44];
export const listNumberBeTo = () => [5, 6, 7, 8, 9, 15, 16, 17, 18, 19, 25, 26, 27, 28, 29, 35, 36, 37, 38, 39, 45, 46, 47, 48, 49];
export const listNumberToBe = () => [50, 51, 52, 53, 54, 60, 61, 62, 63, 64, 70, 71, 72, 73, 74, 80, 81, 82, 83, 84, 90, 91, 92, 93, 94];
export const listNumberToTo = () => [55, 56, 57, 58, 59, 65, 66, 67, 68, 69, 75, 76, 77, 78, 79, 85, 86, 87, 88, 89, 95, 96, 97, 98, 99];

export const listNumberCC = () => [0, 2, 4, 6, 8, 20, 22, 24, 26, 28, 40, 42, 44, 46, 48, 60, 62, 64, 66, 68, 80, 82, 84, 86, 88];
export const listNumberCL = () => [1, 3, 5, 7, 9, 21, 23, 25, 27, 29, 41, 43, 45, 47, 49, 61, 63, 65, 67, 69, 81, 83, 85, 87, 89];
export const listNumberLC = () => [10, 12, 14, 16, 18, 30, 32, 34, 36, 38, 50, 52, 54, 56, 58, 70, 72, 74, 76, 78, 90, 92, 94, 96, 98];
export const listNumberLL = () => [11, 13, 15, 17, 19, 31, 33, 35, 37, 39, 51, 53, 55, 57, 59, 71, 73, 75, 77, 79, 91, 93, 95, 97, 99];

export const getNumWithSum10 = () => [0, 19, 28, 37, 46, 55, 64, 73, 82, 91]

export const COMP_MAP = [
    {
        key: "bo",
        label: "Bộ", 
        comp: x => calObComposition(x), 
    },
    {
        key: "dan",
        label: "Dàn", 
        comp: x => calNadComposition(x), 
    },
    {
        key: "kep",
        label: "Kép", 
        comp: calPekComposition(), 
    }, 
    {
        key: "satkep",
        label: "Sát Kép", 
        comp: calMostPekComposition(), 
    }, 
    {
        key: "sumx",
        label: "Tổng X", 
        comp: x => findNumbersWithDigitSum(x), 
    }, 
    {
        key: "sumbelow10",
        label: "Tổng dưới 10", 
        comp: listNumbersWithSumBelow10(), 
    }, 
    {
        key: "sumabove10",
        label: "Tổng trên 10", 
        comp: listNumbersWithSumAbove10(), 
    }, 
    {
        key: "sum10",
        label: "Tổng 10", 
        comp: getNumWithSum10(), 
    }, 
    {
        key: "bebe",
        label: "Bé Bé", 
        comp: listNumberBebe(),
    },
    {
        key: "beto",
        label: "Bé To", 
        comp: listNumberBeTo(),
    },
    {
        key: "tobe",
        label: "To bé", 
        comp: listNumberToBe(),
    },
    {
        key: "toto",
        label: "To To", 
        comp: listNumberToTo(),
    },
    {
        key: "cc",
        label: "Chẵn Chẵn", 
        comp: listNumberCC(),
    },
    {
        key: "cl", 
        label: "Chẵn Lẻ",
        comp: listNumberCL(),
    },
    {
        key: "lc", 
        label: "Lẻ Chẵn",
        comp: listNumberLC(), 
    },
    {
        key: "ll", 
        label: "Lẻ Lẻ",
        comp: listNumberLL(),
    }
]