import React, { useRef, useState } from "react";
import Combo from "./Combo";

function BeautifulForm() {
  const [lRows, setLRows] = useState([{}]); // Initial empty rows for L & D
  const [dRows, setDRows] = useState([{}]); // Initial empty rows for L & D
  const [xRows, setXRows] = useState([{}]); // Initial empty row for X

  const [dialogStatus, setDialogStatus] = useState(false);

  const lForm = useRef(null);
  const dForm = useRef(null);
  const xForm = useRef(null);

  const addRow = (type) => {
    switch (type) {
      case "l":
        setLRows([...lRows, {}]);
        break;
      case "d":
        setDRows([...dRows, {}]);
        break;
      case "x":
        setXRows([...xRows, {}]);
        break;
      default:
        return; // Ignore non-supported types
    }
    return false;
  };

  //   const onChangeInput

  const onSubmit = async (evt) => {
    evt.preventDefault(); // Prevent default form submission

    const xFormData = new FormData(xForm.current);
    const xList = [];
    if (xFormData) {
      const points = xFormData.getAll("point");
      const messages = xFormData.getAll("message");
      const types = xFormData.getAll("type");
      const delimiters = xFormData.getAll("delimiter");

      for (let i = 0; i < points.length; i++) {
        xList.push({
          point: points[i],
          message: messages[i],
          type: types[i],
          delimiter: delimiters[i],
        });
      }
    }
    const lFormData = new FormData(lForm.current);
    const lList = [];
    if (lFormData) {
      const points = lFormData.getAll("point");
      const messages = lFormData.getAll("message");

      for (let i = 0; i < points.length; i++) {
        lList.push({
          point: points[i],
          message: messages[i],
        });
      }
    }
    const dFormData = new FormData(dForm.current);
    const dList = [];
    if (dFormData) {
      const points = dFormData.getAll("point");
      const messages = dFormData.getAll("message");

      for (let i = 0; i < points.length; i++) {
        dList.push({
          point: points[i],
          message: messages[i],
        });
      }
    }

    const payload = { d: dList, l: lList, x: xList };
    const fetchData = fetch("http://api.halogenx.cloud/result", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }).then(data => {
      return data.json();
    }).then(r => {
      console.log("r", r);
      alert(`l: ${r.l.in}/${r.l.out}
            d: ${r.d.in}/${r.d.out}
            x: ${r.x.in}/${r.x.out}`);
      return r;
    })

  };

  const onOpenDialog =
    (evt) => {
      evt.preventDefault();
      setDialogStatus(true);
    }

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 gap-4">
        {/* Column L */}
        <form ref={lForm}>
          <div className="border rounded-md p-4">
            <h2 className="text-lg font-bold mb-2">L</h2>
            <div className="flex">
              <div className="w-1/6">STT</div>
              <div className="w-3/6">Tin</div>
              <div className="w-1/6">Điểm</div>
              <div className="w-1/6">
                <span
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 cursor-pointer"
                  onClick={() => addRow("l")}
                >
                  +
                </span>
              </div>
            </div>
            {lRows.map((_, index) => (
              <div key={index} className="flex my-1">
                <div className="w-1/6"> {index + 1} </div>
                <div className="w-3/6 px-1 relative">
                  <input className="w-full text-black" name="message" />
                  <button className="absolute right-0 rounded-full bg-cyan-200 w-8 h-8 text-base" onClick={onOpenDialog}> S </button>
                </div>
                <div className="w-2/6 px-1">
                  <input
                    className="w-full text-black"
                    type="number"
                    name="point"
                  />
                </div>
              </div>
            ))}
          </div>
        </form>
        {/* Column D (Similar to L) */}{" "}
        <form ref={dForm}>
          <div className="border rounded-md p-4">
            <h2 className="text-lg font-bold mb-2">D</h2>
            <div className="flex">
              <div className="w-1/6">STT</div>
              <div className="w-3/6">Tin</div>
              <div className="w-1/6">Điểm</div>
              <div className="w-1/6">
                <span
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 cursor-pointer"
                  onClick={() => addRow("d")}
                >
                  +
                </span>
              </div>
            </div>
            {dRows.map((_, index) => (
              <div key={index} className="flex my-1">
                <div className="w-1/6"> {index + 1} </div>
                <div className="w-3/6 px-1">
                  <input className="w-full text-black" name="message" />
                </div>
                <div className="w-2/6 px-1">
                  <input
                    className="w-full text-black"
                    type="number"
                    name="point"
                  />
                </div>
              </div>
            ))}
          </div>
        </form>
        {/* Column X */}
        <form ref={xForm}>
          <div className="border rounded-md p-4">
            <h2 className="text-lg font-bold mb-2">X</h2>
            <div className="flex">
              <div className="w-1/12">STT</div>
              <div className="w-5/12">Tin</div>
              <div className="w-1/12">Điểm</div>
              <div className="w-2/12">Loại</div>
              <div className="w-2/12">Deli</div>
              <div className="w-1/12">
                <span
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 cursor-pointer"
                  onClick={() => addRow("x")}
                >
                  +
                </span>
              </div>
            </div>
            {xRows.map((_, index) => (
              <div key={index} className="flex my-1">
                <div className="w-1/12"> {index + 1} </div>
                <div className="w-5/12 px-1">
                  <input className="w-full text-black" name="message" />
                </div>
                <div className="w-2/12 px-1">
                  <input
                    className="w-full text-black"
                    type="number"
                    name="point"
                  />
                </div>
                <div className="w-2/12 px-1">
                  {/* <input className="w-full text-black" name="type" /> */}
                  <select className="w-full text-black" name="type">
                    <option value="normal">Normal</option>
                    <option value="quay">Quay</option>
                  </select>
                </div>
                <div className="w-2/12 px-1">
                  <input className="w-full text-black" name="delimiter" />
                </div>
              </div>
            ))}
          </div>
        </form>
      </div>
      <button className="border rounded-md p-4 my-2" onClick={onSubmit}>
        {" "}
        Tính{" "}
      </button>
      {dialogStatus && <Combo onClose={() => setDialogStatus(false)}/>}
    </div>
  );
}

export default BeautifulForm;
