import { useState } from "react";
import { COMP_MAP } from "./composition/getCompo";

const Button = ({ isActive, text, onClick }) => {
    if (isActive) return <button onClick={onClick} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{text}</button>
    return <button onClick={onClick} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">{text}</button>
}

const Combo = ({ onClose }) => {
    const [selectedKey, setSelectedKey] = useState(null);
    const [inputNum, setInputNum] = useState("");
    const [combo, setComboResult] = useState("");
    const compKeys = COMP_MAP.map(e => ({ key: e.key, label: e.label }));

    const isInputable = selectedKey ? typeof COMP_MAP.find(e => e.key === selectedKey).comp === "function" : false;

    const handleChangeKey = key => (evt) => {
        evt.preventDefault();
        setSelectedKey(key);
        setInputNum("")
    }

    const onChangeInput = (evt) => {
        setInputNum(evt.target.value);
    };

    const getCombo = (evt) => {
        evt.preventDefault();
        const selectedCombo = COMP_MAP.find(e => e.key === selectedKey).comp;
        if (Array.isArray(selectedCombo))
            setComboResult(selectedCombo.join(","));
        else setComboResult(selectedCombo(parseInt(inputNum, 10)).join(","));
    }

    const copyAndClose = (evt) => {
        evt.preventDefault();
        navigator.clipboard.writeText(combo);
        onClose();
    }

    return <div className="relative z-10" aria-labelledby="modal-title" role="dialog" ariaModal="true">

        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ariaHidden="true"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" ariaHidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Get Combo</h3>
                                <div className="mt-2">
                                    {compKeys.map(data => <Button isActive={data.key === selectedKey} text={data.label} onClick={handleChangeKey(data.key)} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 px-4">
                        {isInputable && <input onChange={onChangeInput} value={inputNum} type="text" id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />}
                    </div>
                    <div className="w-full flex row justify-between p-2 px-4">
                        <span className="text-blue-600 text-xl overflow-hidden text-ellipsis"> {combo} </span>
                        <button className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white" onClick={copyAndClose}> Copy </button>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto" onClick={getCombo}>Get Combo</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Combo;